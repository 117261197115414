// https://stimulus-use.github.io/stimulus-use/#/use-click-outside

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['changeable']
  static values = {
    id: Number
  }
  static last_id = null;

  change(event) {
    if( this.last_id == null ) { this.last_id = event.params.id }
    this.changeableTarget.value = event.params.id

    document
      .querySelector(`#status-${this.last_id} div`)
      .classList.remove('border-2')

    document
      .querySelector(`#status-${event.params.id} div`)
      .classList.add('border-2')

    this.last_id = event.params.id
  }
}
