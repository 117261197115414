import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

// Connects to data-controller="sort"
export default class extends Controller {
  static values = {
    url: String,
    parentId: String
  }

  connect() {
    console.log('-------------------', this.urlValue, this.parentIdValue)
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
    })
  }

  end(event) {
    console.log("sort, end", event, event.item.dataset)
    let data = new FormData()
    data.append("id", event.item.dataset.id)
    data.append("parent_id", this.parentIdValue)
    data.append("position", event.newIndex)
    Rails.ajax({
      url: this.urlValue,
      type: "PATCH",
      data: data,
    })
  }
}
